<template>
    <div class="catalog-product-applications">
        <div class="catalog-product-applications__list">
            <div v-if="assignedApplications && assignedApplications.length === 0">
                <em>No applications assigned</em>
            </div>
            <table class="catalog-product-applications__table">
                <tr
                    v-for="(variantApplication, key) in applicationList"
                    :key="`${key}_${variantApplication.application.applicationId}`"
                >
                    <td class="catalog-product-applications__cell">
                        <mercur-checkbox
                            :data-e2e="'catalogProductContentSectionVariantPublishingApplication-'+key"
                            v-model="selectedApplications"
                            :value="variantApplication.application.applicationId"
                            :disabled="!getApplicationIsReadyToPublishedStatus(variantApplication.application.applicationId)"
                        >
                            <strong class="catalog-product-applications__application-name">{{variantApplication.application.applicationName}}</strong><br />
                        </mercur-checkbox>
                    </td>
                    <td>
                        <status-chip :color="getStatusColor(variantApplication)"><small>{{variantApplication.publishingDetails ? variantApplication.publishingDetails.status : 'UNAVAILABLE'}}</small></status-chip>
                    </td>
                </tr>
            </table>
        </div>
        <div class="text-right">
            <mercur-button data-e2e="catalogProductContentSectionVariantUnpublishButton" class="btn" :disabled="!isAllowedToUnpublish" @click.native="sendVariantApplicationStatusCommand('unpublish')">Unpublish</mercur-button>
            <mercur-button data-e2e="catalogProductContentSectionVariantPublishButton" class="btn btn-primary" :disabled="!isAllowedToPublish" @click.native="sendVariantApplicationStatusCommand('publish')">Publish</mercur-button>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import CONFIG from '@root/config'
import StatusChip from '../../utils/StatusChip'

export default {
    name: 'CatalogProductPublishedVariants',
    components: { StatusChip },
    props: {
        value: { },
        productVariantId: {
            type: String,
            required: true,
        },
        productVariantPublished: {
            type: Boolean,
            required: true,
        },
        assignedApplications: {
        },
    },
    data () {
        return {
            addApplicationDialogActive: false,
            addApplicationDialogPendingApplications: [],
            isSending: false,
            selectedApplications: [],
        }
    },
    computed: {
        ...mapState('shop', ['shops']),
        applicationList () {
            if (!this.assignedApplications) {
                return null
            }

            return this.assignedApplications.map(assignedApplication => {
                const application = this.shops.find(availableShop => availableShop.applicationId === assignedApplication.applicationId)

                return {
                    application,
                    publishingDetails: this.value[assignedApplication.applicationId] || null,
                }
            })
        },
        isAllowedToPublish () {
            if (this.isSending || this.selectedApplications.length === 0) {
                return false
            }
            return this.selectedApplications.some(pendingApplicationId => {
                return this.canPublishApplication(pendingApplicationId)
            })
        },
        isAllowedToUnpublish () {
            if (this.isSending || this.selectedApplications.length === 0) {
                return false
            }
            return this.selectedApplications.some(pendingApplicationId => {
                return this.applicationList.find(assignedApplication => {
                    return assignedApplication.application.applicationId === pendingApplicationId &&
                        assignedApplication.publishingDetails &&
                        assignedApplication.publishingDetails.status !== 'UNAVAILABLE'
                })
            })
        },
    },
    methods: {
        async sendVariantApplicationStatusCommand (type) {
            this.isSending = true
            const url = (type === 'publish' ? CONFIG.API.ROUTES.CATALOG.VARIANTS.APPLICATIONS.PUBLISH : CONFIG.API.ROUTES.CATALOG.VARIANTS.APPLICATIONS.UNPUBLISH)
            return this.post(url, {
                catalogProductId: this.productVariantId,
                applicationIds: this.selectedApplications,
            }, `Applications were successfully ${type}ed`, 'Something went wrong. Please try again.')
                .then(() => {
                    this.$emit('forceFetchCatalogProductVariant')
                    this.$set(this, 'selectedApplications', [])
                }).finally(() => {
                    this.isSending = false
                })
        },
        canPublishApplication (pendingApplicationId) {
            const currentApplication = this.applicationList.find(assignedApplication => assignedApplication.applicationId === pendingApplicationId)
            if (!currentApplication || !currentApplication.publishingDetails) {
                return true
            }

            if (['UNAVAILABLE', 'PENDING_PUBLISHING'].includes(currentApplication.publishingDetails.status)) {
                return true
            }

            return false
        },
        getApplicationIsReadyToPublishedStatus (applicationId) {
            return this.assignedApplications.some(assignedApplication => assignedApplication.applicationId === applicationId && assignedApplication.isReadyToBePublished)
        },
        getStatusColor (variantApplication) {
            switch (variantApplication && variantApplication.publishingDetails && variantApplication.publishingDetails.status) {
            case 'AVAILABLE':
                return 'green'
            case 'PENDING_PUBLISHING':
                return 'blue'
            case 'ERROR':
                return 'red'
            default:
                return 'orange'
            }
        },
    },
    created () {
        if (this.shops.length) {
            return
        }

        this.fetchShops().then(() => {
            this.checkConfig()
        })
    },
}

</script>

<style lang="scss" scoped>
.catalog-product-applications {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__list {
        overflow-y: auto;
        max-height: 210px;
    }
    &__cell {
        font-size: 12px;
        line-height: 1.1em;
        word-break: break-all;
    }
    &__add-button {

    }
    &__dialog {
        width: 80vw;
        max-width: 700px;
        height: 80vh;
        max-height: 700px;
    }
    &__dialog-actions {
        justify-content: space-between;
    }
}
</style>
