<template>
    <div v-if="Object.keys(attributes).length" class="c-attribute-list">
        <span v-for="(attribute, key) in attributes" :key="key">
            <b>{{ key | beautify}}:</b> {{ attribute }}
            <br/>
        </span>
    </div>
    <div v-else>
        <p>-</p>
    </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'Attributes',
    computed: {
        attributes () {
            const validAttributes = {}

            Object.keys(this.params.attributes).forEach((attribute) => {
                if (this.params.attributes[attribute][0]) {
                    validAttributes[attribute] = this.params.attributes[attribute][0]
                }
            })

            return validAttributes
        },
    },
})
</script>

<style lang="scss" scoped>
.c-attribute-list {
    line-height: 1.5rem;
    white-space: normal;
}
</style>
