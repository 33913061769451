<template>
    <div>
        <div>
            <div v-for="attributeName in sortedAvailableAttributeNames" :key="attributeName"
                 class="attribute-section">
                <h2 class="attribute-section__title">{{ attributeName | beautify }} <span
                    v-if="attributeName.includes('#')">(group)</span></h2>
                <catalog-product-setting-attribute-option-selection
                    :attribute-options="value.availableAttributes[attributeName]"
                    :attribute-name="attributeName"
                    :value="value.selectedAttributes[attributeName]"
                    @input="setAttributeValues(attributeName, $event)"
                ></catalog-product-setting-attribute-option-selection>
            </div>
        </div>
    </div>
</template>
<script>
import CatalogProductSettingAttributeOptionSelection from './CatalogProductSettingAttributeOptionSelection'

export default {
    name: 'CatalogProductSettingAttributeBundleSelection',
    components: { CatalogProductSettingAttributeOptionSelection },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        sortedAvailableAttributeNames () {
            const attributeNames = Object.keys(this.value.availableAttributes)
            attributeNames.sort()
            return attributeNames
        },
    },
    methods: {
        setAttributeValues (attributeName, attributeOptions) {
            const value = JSON.parse(JSON.stringify(this.value))
            value.selectedAttributes[attributeName] = attributeOptions
            this.beautifyAndEmitValue(value)
        },
        beautifyAndEmitValue (value = null) {
            if (value === null) {
                value = JSON.parse(JSON.stringify(this.value))
            }

            value.selectedAttributes = Object.fromEntries(Object.entries(value.selectedAttributes)
                .map(([attributeName, values]) => {
                    return [attributeName, values.map(value => {
                        return typeof value === 'string' ? value : value.option
                    })]
                }))
            this.$emit('input', value)
        },
    },
}
</script>
<style scoped lang="scss">
.toggle-groups {
    margin-top: -55px;
    float: right;
}

.slide {
    transition: all 200ms;
    margin-top: 0;
}

.slide-selected-enter,
.slide-selected-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

.slide-selected-leave-active,
.slide-available-leave-active {
    position: absolute;
}

.slide-available-enter,
.slide-available-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}
.slide-available-enter {
    margin-top: -30px;
}
</style>
