<template>
    <div>
        <div>
            <h4>Product Settings</h4>
            <catalog-product-setting-attribute-bundle-selection
                :value="catalogProductSettings"
                @input="handleBundleSelection"
            ></catalog-product-setting-attribute-bundle-selection>
            <hr class="mt-3" />
            <mercur-tabs>
                <mercur-tab title="Editor">
                    <catalog-product-setting :catalogProductSettings="catalogProductSettings" :options="editorOptions" :productVariantId="productVariantId" type="Editor"></catalog-product-setting>
                </mercur-tab>
                <mercur-tab title="EcoCart">
                    <catalog-product-setting :catalogProductSettings="catalogProductSettings" :options="ecoCartOptions" :productVariantId="productVariantId" type="EcoCart"></catalog-product-setting>
                </mercur-tab>
                <mercur-tab title="Claims">
                    <catalog-product-setting :catalogProductSettings="catalogProductSettings" :options="claimsOptions" :productVariantId="productVariantId" type="Claims"></catalog-product-setting>
                </mercur-tab>
                <mercur-tab title="Clothing" v-if="isClothingItem">
                    <catalog-product-setting :catalogProductSettings="catalogProductSettings" :options="clothingOptions" :productVariantId="productVariantId" type="Clothing"></catalog-product-setting>
                </mercur-tab>
                <mercur-tab title="FastEditor">
                    <catalog-product-setting :catalogProductSettings="catalogProductSettings" :options="fastEditorOptions" :productVariantId="productVariantId" type="FastEditor"></catalog-product-setting>
                </mercur-tab>
                <mercur-tab title="Sample" v-if="isSampleEnabled">
                    <catalog-product-setting :catalogProductSettings="catalogProductSettings" :options="sampleOptions" :productVariantId="productVariantId" type="Sample"></catalog-product-setting>
                </mercur-tab>
            </mercur-tabs>
        </div>

    </div>
</template>
<script>

import CatalogProductSetting from '@/components/elements/catalog/CatalogProductSetting'
import ColorCell from '@/components/elements/table/ColorCell'
import Attributes from '@/components/elements/table/Attributes'
import CatalogProductSettingAttributeBundleSelection
    from '@/components/elements/catalog/CatalogProductSettingAttributeBundleSelection'

export default {
    name: 'CatalogProductSettingSection',
    components: {
        CatalogProductSetting,
        CatalogProductSettingAttributeBundleSelection,
    },
    data () {
        return {
            editorOptions: {
                filterModel: { type: {
                    type: 'equals',
                    filter: 'editor',
                    filterType: 'text',
                } },
                columns: {
                    'Type': { field: 'configuration.editor', width: 50 },
                    'Template': { field: 'configuration.templateName', width: 50 },
                    'Color': {
                        field: 'configuration.colorCode',
                        width: 50,
                        cellRendererFramework: ColorCell,
                        sortable: false,
                        menuTabs: [],
                        cellRendererParams: (params) => {
                            return {
                                colorCode: params.value.colorCode,
                            }
                        },
                    },
                    'Attributes': {
                        field: 'attributes',
                        cellRendererFramework: Attributes,
                        cellRendererParams: (params) => {
                            return {
                                attributes: params.data.attributes,
                            }
                        },
                    },
                    'FieldType': { field: 'type', hide: true, suppressToolPanel: true },
                },
                quickSearchColumns: ['configuration.editor', 'configuration.templateName', 'configuration.colorCode', 'attributes'],
            },
            ecoCartOptions: {
                filterModel: {
                    type: {
                        type: 'equals',
                        filter: 'eco_cart',
                        filterType: 'text',
                    },
                },
                columns: {
                    'Active': { field: 'configuration.active', width: 50 },
                    'Product Name': { field: 'configuration.productName', width: 150 },
                    'Attributes': {
                        field: 'attributes',
                        cellRendererFramework: Attributes,
                        cellRendererParams: (params) => {
                            return {
                                attributes: params.data.attributes,
                            }
                        },
                    },
                    'FieldType': { field: 'type', hide: true, suppressToolPanel: true },
                },
                quickSearchColumns: ['configuration.active', 'configuration.productName'],
            },
            claimsOptions: {
                filterModel: {
                    type: {
                        type: 'equals',
                        filter: 'claims',
                        filterType: 'text',
                    },
                },
                columns: {
                    'Claim': { field: 'configuration.claim', width: 150 },
                    'Attributes': {
                        field: 'attributes',
                        cellRendererFramework: Attributes,
                        cellRendererParams: (params) => {
                            return {
                                attributes: params.data.attributes,
                            }
                        },
                    },
                    'FieldType': { field: 'type', hide: true, suppressToolPanel: true },
                },
                quickSearchColumns: ['configuration.claim'],
            },
            clothingOptions: {
                filterModel: {
                    type: {
                        type: 'equals',
                        filter: 'clothing',
                        filterType: 'text',
                    },
                },
                columns: {
                    'Is unisex': { field: 'configuration.isUnisex', width: 100 },
                    'Shirt sizes': { field: 'configuration.shirtSizes', width: 100 },
                    'Attributes': {
                        field: 'attributes',
                        cellRendererFramework: Attributes,
                        cellRendererParams: (params) => {
                            return {
                                attributes: params.data.attributes,
                            }
                        },
                    },
                    'FieldType': { field: 'type', hide: true, suppressToolPanel: true },
                },
                quickSearchColumns: ['configuration.isUnisex', 'configuration.shirtSizes'],
            },
            fastEditorOptions: {
                filterModel: {
                    type: {
                        type: 'equals',
                        filter: 'fast_editor',
                        filterType: 'text',
                    },
                },
                columns: {
                    'Active': { field: 'configuration.active', width: 50 },
                    'FieldType': { field: 'type', hide: true, suppressToolPanel: true },
                },
                quickSearchColumns: ['configuration.active'],
            },
            sampleOptions: {
                filterModel: {
                    type: {
                        type: 'equals',
                        filter: 'sample',
                        filterType: 'text',
                    },
                },
                columns: {
                    'Active': { field: 'configuration.active', width: 50 },
                    'FieldType': { field: 'type', hide: true, suppressToolPanel: true },
                },
                quickSearchColumns: ['configuration.active'],
            },
        }
    },
    props: {
        catalogProductSettings: {
            type: Object,
            default: () => {},
        },
        productVariantId: null,
        isClothingItem: {
            type: Boolean,
            default: false,
        },
        isSampleEnabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleBundleSelection (value) {
            this.updateValueSections([
                {
                    sectionName: 'selectedAttributes',
                    sectionValue: value.selectedAttributes,
                },
                {
                    sectionName: 'availableAttributes',
                    sectionValue: value.availableAttributes,
                }, {
                    sectionName: 'attributeGroups',
                    sectionValue: value.attributeGroups,
                },
            ])
        },
        updateValueSections (sections) {
            const value = JSON.parse(JSON.stringify(this.catalogProductSettings))
            sections.forEach(section => {
                value[section.sectionName] = section.sectionValue
            })
            this.$emit('update:catalogProductSettings', value)
        },
    },
}
</script>
