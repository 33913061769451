<template>
    <div data-e2e="catalogProductOverview" class="row pt-5">
        <div v-if="!isLoaded" class="col col-8 pr-50">
            Loading...
        </div>
        <div v-else class="col col-8 pr-50">
            <div class="row">
                <div class="col col-12" v-if="isVariant && hasWarnings(productVariant)">
                    <catalog-product-content-section title="Warnings">
                        <div v-if="productVariant.hasPendingSmartSpyTasks" class="mt-2">
                            <span style="cursor: pointer;" @click="showPendingSmartSpyTasksInfo = !showPendingSmartSpyTasksInfo">
                                <i v-if="showPendingSmartSpyTasksInfo" class="fas fa-minus"></i>
                                <i v-else class="fas fa-plus"></i>
                            </span>
                            This product variant has pending SmartSpy tasks
                            <div v-if="showPendingSmartSpyTasksInfo" class="mb-3 mt-3">
                                <small>
                                    There are currently SmartSpy pricing tasks being processed for this product.
                                    <br/>
                                    It's possible to reconfigure and publish it, but only once every pending task is finished, will it automatically be pushed into Contentful.
                                </small>
                            </div>
                        </div>
                        <div v-if="productVariant.productIsPossiblyInconsistent">
                            <span style="cursor: pointer;" @click="showInconsistencyNoticeInfo = !showInconsistencyNoticeInfo">
                                <i v-if="showInconsistencyNoticeInfo" class="fas fa-minus"></i>
                                <i v-else class="fas fa-plus"></i>
                            </span>
                            This product variant has been flagged as possibly being inconsistent
                            <div v-if="showInconsistencyNoticeInfo" class="mb-3 mt-3">
                                <small>
                                    This means that at the time of the latest product import some of the SKU's that match with this variant have been removed.
                                    Use the "Check variants" action to check if this is due to any attributes being added or removed, or potentially mapping issues.
                                    If nothing is reported, the attributes might need to be reconfigured below and republished.
                                    <br/>
                                    <b>Note:</b> blacklisted attributes can lead to variants being flagged as inconsistent.
                                </small>
                            </div>
                        </div>
                        <div v-if="productVariant.productIsPossiblyInconsistent">
                            <mercur-button class="btn btn-yellow ml-auto mt-3" @click="clearInconsistentFlagConfigurationOpen = true">
                                Remove inconsistency notice
                            </mercur-button>
                        </div>
                        <mercur-dialog :is-open.sync="clearInconsistentFlagConfigurationOpen">
                            <div slot="header">
                                <h3>Remove inconsistency notice?</h3>
                            </div>
                            <p>
                                Are you sure want to mark this product variant as correctly configured?
                                <br/>
                                Please make sure that everything's correctly configured and published.
                            </p>
                            <template #footer>
                                <mercur-button class="btn" @click.native="clearInconsistentFlagConfigurationOpen = false">Cancel</mercur-button>
                                <mercur-button @click="clearInconsistentFlag" class="btn btn-primary">Confirm</mercur-button>
                            </template>
                        </mercur-dialog>
                    </catalog-product-content-section>
                </div>
                <div class="col col-6">
                    <catalog-product-content-section
                        data-e2e="catalogProductContentSection"
                        :title="isMainProduct ? 'Default product information' : 'Variant information'"
                        section="general"
                        v-bind="sectionBinding"
                        @sectionSaved="onSectionSaved"
                        @discard="$refs.tags.redraw()"
                    >
                        <template slot-scope="{ section }">
                            <div class="pb-5">
                                <div class="text-mini">Marketplace product name</div>
                                <span>{{product.productName}}</span>
                            </div>
                            <div v-if="isMainProduct">
                                <div class="text-mini">Product name</div>
                                <span>{{(product.productContent && product.productContent.productName) || ''}}</span>
                            </div>
                            <div v-if="isMainProduct">
                                <mercur-button class="btn btn-yellow w-100 mt-3" @click="checkVariantsAttributes" v-if="product.catalogProductVariants.length > 0">Check variants</mercur-button>
                                <mercur-dialog :is-open.sync="isCheckingVariants" :width="'900px'">
                                    <div class="catalog-product-applications__dialog">
                                        <div v-for="productVariant in checkVariantsResult.variants" :key="productVariant.catalogProductId">
                                            <div class="variants__item d-flex align-items-center mb-3">
                                                <template v-if="checkVariantErrorsCount(productVariant) === 0">
                                                    <div>
                                                        <status-chip color="green"><small>0 error(s)</small></status-chip>
                                                        <router-link :to="getProductVariantLink(productVariant.catalogProductId)">
                                                            {{productVariant.productName}}
                                                        </router-link>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="row">
                                                        <div class="col-md-12 d-flex align-items-center mb-3">
                                                            <status-chip color="red"><small>{{ checkVariantErrorsCount(productVariant) }} error(s)</small></status-chip>
                                                            <router-link :to="getProductVariantLink(productVariant.catalogProductId)">
                                                                {{productVariant.productName}}
                                                            </router-link>
                                                        </div>
                                                        <div class="col-md-12 variants__item d-flex align-items-center" v-for="addedAttribute in productVariant.checkResult.addedAttributes" :key="addedAttribute">
                                                            The "{{ addedAttribute }}" attribute was added and needs to be mapped
                                                        </div>
                                                        <div class="col-md-12 variants__item d-flex align-items-center" v-for="removedAttribute in productVariant.checkResult.removedAttributes" :key="removedAttribute">
                                                            The attribute "{{ removedAttribute }}" was removed
                                                        </div>
                                                        <div class="col-md-12 variants__item d-flex align-items-center" v-for="removedValue in productVariant.checkResult.removedValues" :key="removedValue.attribute">
                                                            The "{{ removedValue.value }}" value of the attribute "{{ removedValue.attribute }}" was removed
                                                            <div class="blacklist-text" v-if="checkVariantsResult.blacklistedAttributes[removedValue.attribute] && checkVariantsResult.blacklistedAttributes[removedValue.attribute].includes(removedValue.value)">(Might have been blacklisted)</div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div slot="header">
                                        <h3>Check variants result</h3>
                                    </div>
                                    <div slot="footer">
                                        <mercur-button class="btn" @click.native="isCheckingVariants = false">Close</mercur-button>
                                    </div>
                                </mercur-dialog>
                            </div>
                            <div v-else-if="isVariant">
                                <mercur-input data-e2e="catalogProductContentSectionProductNameInput" :value="section.productName.$model" disabled readonly>
                                    Product name
                                    <template #note>
                                        <span class="form-error" v-if="section.productName.$error">Last name is required</span>
                                    </template>
                                </mercur-input>
                                <div class="pt-10 row">
                                    <div class="col col-7">
                                        <pretty-select
                                            v-if="availableTags"
                                            placeholder="Tags"
                                            :taggable="true"
                                            :multiple="true"
                                            v-model="section.productTags.$model"
                                            :options="availableTags.filter(tag => section.productTags.$model === null || !section.productTags.$model.includes(tag))"
                                            ref="tags"
                                        ></pretty-select>
                                    </div>
                                </div>
                                <mercur-textarea
                                    data-e2e="catalogProductContentSectionProductDescription"
                                    v-model.trim="section.productDescription.$model">
                                    Description
                                </mercur-textarea>
                            </div>
                        </template>
                    </catalog-product-content-section>
                </div>
                <div class="col col-6" v-if="isMainProduct">
                    <catalog-product-content-section
                        title="Assigned applications"
                    >
                        <catalog-product-assigned-applications
                            v-model="product.assignedApplications"
                            :catalog-product-id="this.catalogProductId"
                            :product="product"
                            @forceFetchCatalogProduct="$emit('fetchProductContent')"
                        ></catalog-product-assigned-applications>
                    </catalog-product-content-section>
                </div>
                <div class="col col-6" v-if="isVariant">
                    <catalog-product-content-section
                        title="Variant details"
                    >

                        <div v-if="productVariant" class="external-cms-pages">
                            <label class="text-mini">External cms urls</label>
                            <div class="external-cms-pages__list">
                                <div v-for="(page, key) in externalCmsPages" :key="key" class="d-flex align-items-center pb-1">
                                    <mercur-input
                                        :data-e2e="`catalogProductVariantDetailsCmsInput${key}`"
                                        :clearable="false"
                                        class="flex-grow-1 mt-0 mb-0"
                                        v-model="productVariant.externalCmsPages[key]">
                                    </mercur-input>
                                    <mercur-button
                                        :disabled="!productVariant"
                                        @click="productVariant.externalCmsPages.splice(key, 1)"
                                        class="btn btn-danger mt-0"
                                    ><i class="fas fa-times pl-2"></i></mercur-button>
                                </div>
                            </div>
                            <div class="mb-4 mt-2">
                                <mercur-button
                                    class="btn btn-sm btn-yellow"
                                    data-e2e="catalogProductVariantDetailsAddAnotherUrl"
                                    :disabled="!productVariant"
                                    @click="productVariant.externalCmsPages.push('')"
                                ><i class="fas fa-plus"></i>Add another url</mercur-button>
                            </div>
                        </div>
                        <mercur-input
                            disabled
                            readonly
                            :value="productVariant && productVariant.productSlug">
                            Product slug (read only)
                        </mercur-input>
                        <mercur-button
                            class="btn btn-primary"
                            data-e2e="catalogProductContentSectionVariantInformationSaveUrlButton"
                            @click.native="updateProductVariant"
                        >Save</mercur-button>
                    </catalog-product-content-section>
                </div>
                <div class="col col-6" v-if="isMainProduct">
                    <catalog-product-content-section
                        title="Attributes"
                    >
                        <div v-if="product.productAttributes">
                            <div v-for="(attributeOptions, attributeName) in sortAttributes(product.productAttributes.selectedAttributes)" :key="attributeName" class="attribute-section">
                                <h2 class="attribute-section__title">{{attributeName | beautify}}</h2>
                                <mercur-chip
                                    v-for="attributeOption in attributeOptions"
                                    :key="attributeOption.option"
                                    class="attribute-section__option attribute-section__option--is-selected"
                                >
                                    {{attributeOption.option | beautify}}
                                </mercur-chip>
                            </div>
                        </div>
                    </catalog-product-content-section>
                </div>
                <div class="col col-12" v-if="isVariant">
                    <catalog-product-content-section title="Notes">
                        <mercur-textarea v-model.trim="productVariant.notes"></mercur-textarea>
                        <mercur-button class="btn btn-primary" @click.native="updateProductVariant">
                            Save
                        </mercur-button>
                    </catalog-product-content-section>
                </div>
                <div class="col-12" v-if="isVariant">
                    <catalog-product-content-section>
                        <variant-attribute-selection
                            v-if="productVariant && productVariant.productAttributes"
                            :allAttributes="sortAttributes(product.productAttributes.selectedAttributes)"
                            v-model="productVariant.productAttributes"
                            :product="product"
                            @triggerSave="updateProductVariant"
                            ref="variantAttributeSelection"
                        ></variant-attribute-selection>
                    </catalog-product-content-section>
                </div>
                <div class="col col-6" v-if="isMainProduct">
                    <catalog-product-content-section
                        title="Images (readonly)"
                    >
                        <template v-if="product && product.productContent && product.productContent.productContentImages">
                            <div v-for="(image, key) in product.productContent.productContentImages" :key="key" class="product-image">
                                <div class="product-image__image" :style="{backgroundImage: getImageUrl(image)}"></div>
                                <div class="product-image__text">
                                    <span class="product-image__filename">{{image.imageFilename}}</span>
                                    <span class="product-image__caption" v-if="image.imagePrimary">Primary image</span>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <em>No images found for this product</em>
                        </template>
                    </catalog-product-content-section>
                </div>
                <div class="col col-6" v-if="isVariant">
                    <catalog-product-content-section
                        title="Images (First 3 from Contentful)"
                    >
                        <template v-if="imagesSize > 0">
                            <transition-group name="list-complete" tag="p">
                                <div v-for="(image, key, index) in images" :key="key" class="product-image">
                                    <div class="product-image__image" :style="{backgroundImage: getImageUrl(image.$model)}" :id="index"></div>
                                </div>
                            </transition-group>
                        </template>
                        <template v-else>
                            <em>No images found for this product</em>
                        </template>
                    </catalog-product-content-section>
                </div>
                <div class="col col-6" v-if="isVariant">
                    <catalog-product-content-section
                        title="Submission specifications"
                        section="submissionSpecifications"
                        v-bind="sectionBinding"
                        @sectionSaved="onSectionSaved"
                    >
                        <template slot="topActions">
                            <mercur-button
                                data-e2e="catalogProductContentSectionVariantAddSpecificationButton"
                                class="btn btn-raised ml-auto mb-3 linked-entries-add-button"
                                @click.native="$refs.submissionSpecifications.openNew()"
                            ><i class="fas fa-plus"></i>Add</mercur-button>
                        </template>
                        <template slot-scope="{ section }">
                            <linked-entries
                                data-e2e="catalogProductContentSectionVariantSpecifications"
                                :available-entries="availableSubmissionSpecifications"
                                unique-key="submissionSpecificationId"
                                title-key="submissionSpecificationTitle"
                                content-key="submissionSpecificationContent"
                                position-key="submissionSpecificationPosition"
                                v-model="section.productContentSubmissionSpecifications.$model"
                                section-title="Submission specifications"
                                ref="submissionSpecifications"
                            ></linked-entries>
                        </template>
                    </catalog-product-content-section>
                </div>
                <div class="col col-6" v-if="isVariant">
                    <catalog-product-content-section
                        title="FAQ"
                        section="faqs"
                        v-bind="sectionBinding"
                        @sectionSaved="onSectionSaved"
                    >
                        <template slot="topActions">
                            <mercur-button
                                data-e2e="catalogProductContentSectionVariantAddFaqButton"
                                class="btn btn-raised ml-auto mb-3 linked-entries-add-button"
                                @click="$refs.faqs.openNew()"
                            ><i class="fas fa-plus"></i>Add</mercur-button>
                        </template>
                        <template slot-scope="{ section }">
                            <linked-entries
                                data-e2e="catalogProductContentSectionVariantFaqs"
                                :available-entries="availableFaqs"
                                unique-key="faqId"
                                title-key="faqQuestion"
                                content-key="faqAnswer"
                                position-key="faqPosition"
                                v-model="section.productContentFaq.$model"
                                section-title="Faqs"
                                ref="faqs"
                            ></linked-entries>
                        </template>
                    </catalog-product-content-section>
                </div>
                <div class="col-12" v-if="isVariant">
                    <catalog-product-content-section v-if="productVariant && productVariant.catalogProductSettings">
                        <catalog-product-setting-section
                            :catalogProductSettings.sync="productVariant.catalogProductSettings"
                            :isClothingItem.sync="productVariant.isClothingItem"
                            :isSampleEnabled.sync="productVariant.isSampleEnabled"
                            :productVariantId="productVariantId"
                            ref="variantExternalEditorAttributeSelection"
                        ></catalog-product-setting-section>
                    </catalog-product-content-section>
                </div>
            </div>
        </div>
        <div v-if="isLoaded" class="col col-4">
            <div>
                <catalog-product-content-section
                    data-e2e="catalogMainVariantsList"
                    title="Product variants"
                >
                    <template v-if="isLoaded">
                        <div class="variants">
                            <div class="variants__item variants__item--main d-flex align-items-center">
                                <router-link data-e2e="catalogMainVariantLink" :to="getMainProductLink()">
                                    <span :class="!productVariantId ? 'u-bold' : ''">{{(product.productContent && product.productContent.productName) || ''}}</span>
                                    <span>&nbsp;(main product)</span>
                                </router-link>
                                <mercur-menu class="ml-2">
                                    <mercur-button class="btn btn-icon"><i class="fas fa-ellipsis-v"></i></mercur-button>
                                    <template slot="dropdown">
                                        <router-link :to="getMainProductLink()"><i class="fas fa-eye"></i>View</router-link>
                                        <mercur-tooltip :is-tooltip-available="!isAllowedToDeleteCatalogProduct">
                                            <template #label>You can only delete a catalog product if all variants are removed.</template>
                                            <mercur-item @click.native="openConfirmDeleteVariant()" :disabled="!isAllowedToDeleteCatalogProduct">
                                                <i class="fas fa-trash"></i>
                                                Delete catalog product
                                            </mercur-item>
                                        </mercur-tooltip>
                                    </template>
                                </mercur-menu>
                            </div>
                            <div v-if="product.catalogProductVariants.length === 0">
                                <em>This product has no variants</em>
                            </div>
                            <div v-for="productVariant in product.catalogProductVariants" :key="productVariant.catalogProductId">
                                <div class="variants__item d-flex align-items-center">
                                    <router-link :to="getProductVariantLink(productVariant.catalogProductId)" :class="productVariant.catalogProductId === productVariantId ? 'u-bold' : ''">
                                        {{productVariant.productName}}
                                        <template v-if="productVariant.productIsPossiblyInconsistent">
                                            <mercur-tooltip>
                                                <template #label>Variant is possibly inconsistent</template>
                                                <span class="text-danger">
                                                    <i class="fas fa-exclamation-triangle"></i>
                                                </span>
                                            </mercur-tooltip>
                                        </template>
                                    </router-link>
                                    <small>
                                        <a :href="productVariant.contentfulUrl" target="_blank" rel="noopener noreferrer">
                                            <status-chip :color="getStatusColor(productVariant)"><small>{{productVariant && productVariant.status}}</small></status-chip>
                                            <template v-if="hasBeenPublished(productVariant)">
                                                <mercur-tooltip>
                                                    <template #label>Last Update: {{productVariant.dateUpdated}}</template>
                                                    <status-chip v-if="isVariantUpToDate(productVariant)" color="green"><small>OK</small></status-chip>
                                                    <status-chip v-else><small>Outdated</small></status-chip>
                                                </mercur-tooltip>
                                            </template>
                                        </a>
                                    </small>
                                    <mercur-menu class="ml-2">
                                        <mercur-button data-e2e="catalogNewVariantMenu" class="btn btn-icon"><i class="fas fa-ellipsis-v"></i></mercur-button>
                                        <template slot="dropdown">
                                            <router-link :to="getProductVariantLink(productVariant.catalogProductId)">
                                                <i class="fas fa-eye"></i>View
                                            </router-link>
                                            <a v-if="canPublishVariant(productVariant)" @click="publishVariant(productVariant)">
                                                <i class="fas fa-upload"></i>
                                                Publish
                                            </a>
                                            <a v-if="canUnpublishVariant(productVariant)" @click="unpublishVariant(productVariant)">
                                                <i class="fas fa-times-circle"></i>
                                                Unpublish
                                            </a>
                                            <a @click="copyProductSlug(productVariant.catalogProductId)">
                                                <i class="fas fa-copy"></i>
                                                {{productVariant.productSlug}} <small>Click to copy slug</small>
                                            </a>
                                            <a @click="openCloneVariant(productVariant.catalogProductId)">
                                                <i class="fas fa-clone"></i>Clone
                                            </a>
                                            <a data-e2e="catalogNewVariantMenuDeleteOption" @click="openConfirmDeleteVariant(productVariant.catalogProductId)">
                                                <i class="fas fa-trash"></i>Delete
                                            </a>
                                        </template>
                                    </mercur-menu>
                                </div>
                            </div>
                        </div>
                        <form @submit.prevent="createNewVariant">
                            <mercur-input data-e2e="catalogCreateNewVariantInput" required :disabled="isCreatingVariant" v-model="variantDetails.productName">Create new variant</mercur-input>
                            <mercur-button data-e2e="catalogCreateNewVariantCreateButton" class="btn btn-raised" type="submit" :disabled="!variantDetails.productName || isCreatingVariant">Create new variant</mercur-button>
                        </form>
                        <mercur-dialog :is-open.sync="deleteVariantConfirmationOpen">
                            <h3 class="font-weight-normal">Please confirm delete</h3>
                            <p>Are you sure you want to remove this {{deleteVariantConfirmationVariantId ? 'variant' : 'catalog product'}}? You cannot undo this.<br /><br />The variant will also be unpublished for the applications it's assigned to.</p>
                            <template #footer>
                                <mercur-button class="btn" @click.native="deleteVariantConfirmationOpen = false">Cancel</mercur-button>
                                <mercur-button data-e2e="catalogNewVariantMenuDeleteConfirmationButton" @click.native="() => deleteVariantConfirmationVariantId ? deleteProductVariant(deleteVariantConfirmationVariantId) : deleteCatalogProduct()" class="btn btn-primary">Remove</mercur-button>
                            </template>
                        </mercur-dialog>
                        <mercur-dialog :is-open.sync="cloneVariantConfirmationOpen">
                            <h3 class="font-weight-normal">Product variant name</h3>
                            <p>Please enter the name for the new product variant</p>
                            <mercur-input v-model="cloneVariantConfirmationCloneName">Variant name</mercur-input>
                            <template #footer>
                                <mercur-button class="btn" @click.native="cloneVariantConfirmationOpen = false">Cancel</mercur-button>
                                <mercur-button class="btn btn-primary" @click.native="confirmCloneVariant">Clone</mercur-button>
                            </template>
                        </mercur-dialog>
                    </template>
                </catalog-product-content-section>
                <catalog-product-content-section
                    v-if="isVariant"
                    title="Applications"
                >
                    <catalog-product-published-variants
                        v-model="productVariant.publishedApplications"
                        :assigned-applications="product.assignedApplications"
                        :product-variant-id="productVariantId"
                        :product-variant-published="isVariantPublished(productVariant)"
                        @forceFetchCatalogProductVariant="$emit('fetchCatalogProductVariant')"
                    ></catalog-product-published-variants>
                </catalog-product-content-section>
                <catalog-product-content-section
                    v-if="isVariant && productVariant.assignedVatProfiles && Object.keys(productVariant.assignedVatProfiles).length"
                    title="VAT Profile"
                >
                    <catalog-product-vat-profiles-list
                        :productVariant="productVariant"
                    ></catalog-product-vat-profiles-list>

                </catalog-product-content-section>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import PrettySelect from '../../../components/utils/PrettySelect'
import CatalogProductContentSection from '../../../components/elements/catalog/CatalogProductContentSection'
import CONFIG from '@root/config'
import LinkedEntries from '@platform-frontend-components/linked-entries'
import CatalogImages from '../../../components/elements/catalog/CatalogImages'
import VariantAttributeSelection from '../../../components/elements/catalog/VariantAttributeSelection'
import CatalogProductSettingSection from '../../../components/elements/catalog/CatalogProductSettingSection'
import CatalogProductAssignedApplications from '../../../components/elements/catalog/CatalogProductAssignedApplications'
import { copyToClipboard } from '@/utils/helpers'
import CatalogProductPublishedVariants from '../../../components/elements/catalog/CatalogProductPublishedVariants'
import CatalogProductVatProfilesList from '@/components/elements/catalog/CatalogProductVatProfilesList'
import StatusChip from '@/components/utils/StatusChip'

export default {
    name: 'CatalogProductEdit',
    components: {
        CatalogProductVatProfilesList,
        CatalogProductPublishedVariants,
        CatalogProductAssignedApplications,
        VariantAttributeSelection,
        CatalogProductSettingSection,
        CatalogProductContentSection,
        PrettySelect,
        LinkedEntries,
        StatusChip,
    },
    mixins: [ CatalogImages ],
    props: {
        productContent: {},
        catalogProductId: {},
        product: {},
        productVariant: {},
        productVariantId: null,
    },
    data () {
        return {
            availableSubmissionSpecifications: [],
            availableFaqs: [],
            availableTags: [],
            form: null,
            variantDetails: {
                productName: null,
            },
            isCreatingVariant: false,
            isCheckingVariants: false,
            checkVariantsResult: [],
            clearInconsistentFlagConfigurationOpen: false,
            deleteVariantConfirmationOpen: false,
            deleteVariantConfirmationVariantId: null,
            cloneVariantConfirmationOpen: false,
            cloneVariantConfirmationVariantId: null,
            cloneVariantConfirmationCloneName: null,
            showInconsistencyNoticeInfo: false,
            showPendingSmartSpyTasksInfo: false,
        }
    },
    computed: {
        externalCmsPages () {
            if (this.productVariant && this.productVariant.externalCmsPages && this.productVariant.externalCmsPages.length) {
                return this.productVariant.externalCmsPages
            }
            return ['']
        },
        isAllowedToDeleteCatalogProduct () {
            return Object.keys(this.product.catalogProductVariants).length === 0
        },
        sectionBinding () {
            return {
                vForm: this.$v.form,
                getProductContentBySection: this.getProductContentBySection,
                catalogProductId: this.catalogProductId,
                productContentId: this.productContent ? this.productContent.productContentId : null,
                isLoaded: this.isLoaded,
                isMainProduct: this.isMainProduct,
            }
        },
        isMainProduct () {
            return this.isLoaded && !this.productVariantId
        },
        isVariant () {
            return !!(this.isLoaded && this.productVariantId && this.productVariant)
        },
        isLoaded () {
            return !!this.product
        },
    },
    validations: {
        form: {
            general: {
                productName: {
                    required,
                },
                productTags: { },
                productDescription: { },
            },
            images: {
                productContentImages: {
                    $each: { },
                },
            },
            submissionSpecifications: {
                productContentSubmissionSpecifications: { },
            },
            faqs: {
                productContentFaq: { },
            },
        },
    },
    methods: {
        sortAttributes (attributes) {
            return Object.fromEntries(Object.entries(attributes).sort((a, b) => a[0].localeCompare(b[0])))
        },
        onSectionSaved ({ section }) {
            this.$emit('fetchCatalogProductVariant')
            if (section === 'general') {
                this.$emit('fetchProductContent')
            }
        },
        confirmCloneVariant () {
            const url = CONFIG.API.ROUTES.CATALOG.VARIANTS.CLONE.replace('{productVariantId}', this.cloneVariantConfirmationVariantId)
            this.post(url, { productName: this.cloneVariantConfirmationCloneName }, 'Variant successfully cloned. Redirecting now', 'Something went wrong. Please try again')
                .then(({ data }) => {
                    this.$router.push(this.getProductVariantLink(data.catalogProductId))
                    this.$set(this, 'cloneVariantConfirmationVariantId', null)
                    this.$set(this, 'cloneVariantConfirmationCloneName', null)
                    this.$set(this, 'cloneVariantConfirmationOpen', false)
                })
        },
        openCloneVariant (productVariantId) {
            this.$set(this, 'cloneVariantConfirmationVariantId', productVariantId)
            this.$set(this, 'cloneVariantConfirmationOpen', true)
        },
        openConfirmDeleteVariant (productVariantId) {
            this.$set(this, 'deleteVariantConfirmationOpen', true)
            this.$set(this, 'deleteVariantConfirmationVariantId', productVariantId)
        },
        copyProductSlug (productVariantId) {
            const productVariant = this.product.catalogProductVariants.find(variant => variant.catalogProductId === productVariantId)

            if (!productVariant) {
                this.$root.$emit('notification:global', {
                    message: 'Product variant cannot be found',
                    type: 'error',
                })
                return
            }

            copyToClipboard(productVariant.productSlug)
            this.$root.$emit('notification:global', {
                message: `Slug for ${productVariant.productName} (${productVariant.productSlug}) has been copied to the clipboard.`,
            })
        },
        async publishVariant (productVariant) {
            // check if every available attribute has at least one value selected
            const hasInvalidBundleError = Object.keys(productVariant.productAttributes.availableAttributes).length !==
                Object.keys(productVariant.productAttributes.selectedAttributes).filter((attribute) => productVariant.productAttributes.selectedAttributes[attribute].length).length

            if (hasInvalidBundleError) {
                this.$root.$emit('notification:global', {
                    message: 'Every attribute must have at least one selected option.',
                    type: 'error',
                })

                return
            }

            return this.post(CONFIG.API.ROUTES.CATALOG.VARIANTS.PUBLISH.replace('{productVariantId}', productVariant.catalogProductId), {}, 'Variant publishing started', 'Something went wrong. Please try again.').then(() => {
                this.$emit('fetchProductContent')
                this.$emit('fetchCatalogProductVariant')
            })
        },
        async unpublishVariant (productVariant) {
            return this.post(CONFIG.API.ROUTES.CATALOG.VARIANTS.UNPUBLISH.replace('{productVariantId}', productVariant.catalogProductId), {}, 'Variant was unpublished', 'Something went wrong. Please try again.').then(() => {
                this.$emit('fetchProductContent')
                this.$emit('fetchCatalogProductVariant')
            })
        },
        async deleteProductVariant (productVariantIdToDelete) {
            this.deleteVariantConfirmationOpen = false
            return this.post(CONFIG.API.ROUTES.CATALOG.VARIANTS.DELETE.replace('{productVariantId}', productVariantIdToDelete), {}, 'Variant was successfully deleted.', 'Something went wrong. Please try again.').then(() => {
                if (this.productVariantId !== productVariantIdToDelete) {
                    this.$emit('fetchProductContent')
                    return
                }

                // find the first available variant and redirect to it or the main product
                const catalogProductVariants = Object.keys(this.productVariant.catalogProductVariants).filter(availableVariantId => availableVariantId !== productVariantIdToDelete)
                if (catalogProductVariants < 2) {
                    this.$router.replace(this.getMainProductLink())
                    return
                }

                this.$router.replace(this.getProductVariantLink(catalogProductVariants[0]))
            })
        },
        async deleteCatalogProduct () {
            await this.post(CONFIG.API.ROUTES.CATALOG.DELETE_CATALOG_PRODUCT.replace('{catalogProductId}', this.catalogProductId), {}, 'Catalog product was successfully deleted.', 'Something went wrong. Please try again.')
            this.deleteVariantConfirmationOpen = false
            this.$router.replace({
                name: 'CatalogWrapper',
                params: {
                    overviewType: 'catalog',
                },
            })
        },
        updateProductVariant () {
            const url = CONFIG.API.ROUTES.CATALOG.VARIANTS.UPDATE.replace('{productVariantId}', this.productVariantId)
            const payload = JSON.parse(JSON.stringify(this.productVariant))
            delete payload.productVariants
            this.post(url, payload, 'Successfully saved', 'Something went wrong. Please try again.').then(() => {
                this.$emit('fetchProductContent')
                this.$emit('fetchCatalogProductVariant')
            })
        },
        checkVariantsAttributes () {
            this.post(
                CONFIG.API.ROUTES.CATALOG.CHECK_PRODUCT_VARIANTS_ATTRIBUTES.replace('{catalogProductId}', this.catalogProductId),
                {},
            ).then(({ data }) => {
                this.isCheckingVariants = true
                this.checkVariantsResult = data
            })
        },
        checkVariantErrorsCount (productVariant) {
            return productVariant.checkResult.addedAttributes.length +
                productVariant.checkResult.removedAttributes.length +
                productVariant.checkResult.removedValues.length
        },
        clearInconsistentFlag () {
            this.productVariant.productIsPossiblyInconsistent = 'false'
            this.clearInconsistentFlagConfigurationOpen = false
            this.updateProductVariant()
        },
        getMainProductLink () {
            if (!this.product) {
                return {}
            }
            return {
                name: 'CatalogProductView',
                params: {
                    catalogProductId: this.catalogProductId,
                },
            }
        },
        getProductVariantLink (productVariantId) {
            return {
                name: 'CatalogProductView',
                params: {
                    catalogProductId: this.catalogProductId,
                    productVariantId: productVariantId,
                },
            }
        },
        getProductContentBySection (section) {
            const fieldNames = Object.keys(this.$v.form[section].$params)

            if (!this.productContent || Object.keys(this.productContent).length === 0) {
                return Object.fromEntries(fieldNames.map(field => [field, null]))
            }
            return JSON.parse(JSON.stringify(Object.fromEntries(Object.entries(this.productContent).filter(([key]) => fieldNames.includes(key)))))
        },
        async createNewVariant () {
            if (this.isCreatingVariant) {
                return
            }
            const payload = {
                catalogProductId: this.catalogProductId,
                productName: this.variantDetails.productName,
            }
            this.isCreatingVariant = true
            await this.post(CONFIG.API.ROUTES.CATALOG.VARIANTS.CREATE, payload).then(async ({ data }) => {
                const productVariantId = data.catalogProductId
                const parentCatalogProductId = data.parentCatalogProductId

                const contentPayload = {
                    productStatus: 'AVAILABLE',
                    productDescription: 'Placeholder description',
                    ...this.productContent,
                    productName: this.variantDetails.productName,
                    catalogProductId: productVariantId,
                }

                await this.post(CONFIG.API.ROUTES.CATALOG.CONTENT.CREATE, contentPayload).then(data => {
                    this.$router.push({
                        params: {
                            catalogProductId: parentCatalogProductId,
                            productVariantId: productVariantId,
                        },
                    })
                    this.$set(this.variantDetails, 'productName', null)
                })
            })
            this.isCreatingVariant = false
        },
        canPublishVariant (productVariant) {
            return !this.isVariantPublished(productVariant) || !this.isVariantUpToDate(productVariant)
        },
        canUnpublishVariant (productVariant) {
            return productVariant && productVariant.status !== 'UNAVAILABLE'
        },
        hasBeenPublished (productVariant) {
            return !!(productVariant && productVariant.publishedProductVariantHash)
        },
        isVariantPublished (productVariant) {
            return productVariant && productVariant.status === 'PUBLISHED'
        },
        isVariantUpToDate (productVariant) {
            const publishedHash = productVariant && productVariant.productVariantHash
            const currentHash = productVariant && productVariant.publishedProductVariantHash
            return publishedHash === currentHash
        },
        hasWarnings (productVariant) {
            return productVariant && (productVariant.productIsPossiblyInconsistent || productVariant.hasPendingSmartSpyTasks)
        },
        getStatusColor (productVariant) {
            switch (productVariant && productVariant.status) {
            case 'PUBLISHED':
                return 'green'
            case 'DRAFT':
            case 'QUEUED_FOR_PUBLISHING':
            case 'PUBLISHING':
                return 'blue'
            case 'ERROR':
                return 'red'
            default:
                return 'orange'
            }
        },
    },
    watch: {
        productContent: {
            handler () {
                this.$set(this, 'form', {
                    general: this.getProductContentBySection('general'),
                    images: this.getProductContentBySection('images'),
                    submissionSpecifications: this.getProductContentBySection('submissionSpecifications'),
                    faqs: this.getProductContentBySection('faqs'),
                })
                this.$nextTick(() => {
                    try {
                        this.$refs.tags.updateValues()
                    } catch (e) { }
                    this.$v.form.$reset()
                })
            },
            immediate: true,
        },
    },
    created () {
        this.post(CONFIG.API.ROUTES.CATALOG.TAGS.OVERVIEW, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.$set(this, 'availableTags', data)
        })
        this.post(CONFIG.API.ROUTES.CATALOG.FAQ.OVERVIEW, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.$set(this, 'availableFaqs', data.items)
        })
        this.post(CONFIG.API.ROUTES.CATALOG.SUBMISSION_SPECIFICATIONS.OVERVIEW, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.$set(this, 'availableSubmissionSpecifications', data.items)
        })
    },
}
</script>

<style lang="scss" scoped>
.product-image {
    float: left;
    margin-bottom: 10px;
    display: flex;
    transition: all .5s ease-out;

    &__image {
        width: 135px;
        height: 135px;
        margin-right: 10px;
        background-color: #888;
        flex: 0 0 135px;
        background-size: cover;
        background-position: center center;
    }

    &__caption {
        display: block;
        color: #888888;
    }

    &__actions {
        margin-left: auto;
    }
}

.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
    transform: translateY(30px);
}
.list-complete-leave-active {
    position: absolute;
}
.external-cms-pages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__list {
        overflow-y: auto;
        max-height: 210px;
    }
}
.variants {
    margin-bottom: 20px;

    &__item {
        margin-left: 10px;
        a {
            flex: 1;
        }
        &--main {
            margin-left: 0;
        }
    }
}
.linked-entries-add-button {
    margin-top: -50px;
}
.blacklist-text {
    margin-left: 5px;
}
</style>
