<template>
    <div>
        <div class="row pb-30" v-if="isEditingGroups">
            <div class="col col-8">
                <h3>Groups</h3>
                <mercur-item
                    v-for="attributeGroup in attributeGroups"
                    :key="attributeGroup.join('#')"
                    @click.native="triggerEditGroup(attributeGroup)"
                >
                    {{attributeGroup.join('#')}}
                </mercur-item>
                <mercur-button
                    class="btn btn-yellow"
                    @click="triggerAddNewGroup()"
                >
                    New group <i class="fas fa-plus"></i>
                </mercur-button>
            </div>

            <div class="col col-4" v-if="editingGroup !== null">
                <div class="d-flex align-items-center justify-content-between">
                    <h3>Group details</h3>
                </div>
                <transition-group name="slide-selected">
                    <div v-for="(attributeName, index) in editingGroup" :key="attributeName" class="slide">
                        <strong>{{attributeName}}</strong> <a href="#" @click.prevent="editingGroup.splice(index, 1)"><i class="fas fa-trash"></i></a>
                    </div>
                </transition-group>
                <transition-group name="slide-available">
                    <a
                        href="#"
                        v-for="attributeName in availableAttributesForGrouping"
                        :key="attributeName"
                        class="slide"
                        @click.prevent="addAttributeToGroup(attributeName)">
                        {{attributeName}}<br />
                    </a>
                </transition-group>
                <hr />
                <div class="text-right">
                    <mercur-button
                        class="btn"
                        @click="deleteGroupDialogIsOpen = true"
                    >Delete group &times;</mercur-button>
                    <mercur-button
                        class="btn btn-yellow"
                        @click="saveGroup()"
                        :disabled="editingGroup.length < 2"
                    >
                        Save group <i class="fas fa-save"></i>
                    </mercur-button>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-for="attributeName in sortedAvailableAttributeNames" :key="attributeName"
                 class="attribute-section">
                <h2 class="attribute-section__title">{{ attributeName | beautify }} <span
                    v-if="attributeName.includes('#')">(group)</span></h2>
                <variant-attribute-option-selection
                    :attribute-options="value.availableAttributes[attributeName]"
                    :attribute-name="attributeName"
                    :value="value.selectedAttributes[attributeName]"
                    @input="setAttributeValues(attributeName, $event)"
                ></variant-attribute-option-selection>
            </div>
        </div>
        <mercur-dialog
            :is-open.sync="deleteGroupDialogIsOpen">
            <h2 class="font-weight-normal" slot="header">Are you sure you want to delete this group?</h2>
            <p>All the selected options will be discarded</p>
            <div slot="footer">
                <mercur-button class="btn" @click.native="deleteGroupDialogIsOpen = false">Cancel</mercur-button>
                <mercur-button class="btn btn-yellow btn-raised" @click="deleteGroup()">Delete</mercur-button>
            </div>
        </mercur-dialog>
    </div>
</template>
<script>
import CONFIG from '@root/config'
import VariantAttributeOptionSelection from '@/components/elements/catalog/VariantAttributeOptionSelection'

export default {
    name: 'VariantAttributeBundleSelection',
    components: { VariantAttributeOptionSelection },
    props: {
        allAttributes: {
            type: Object,
            required: true,
        },
        value: {
            required: false,
            default: () => ({}),
        },
        product: {
            required: true,
        },
        isEditingGroups: {
            default: false,
        },
    },
    data () {
        return {
            deleteGroupDialogIsOpen: false,
            editingGroup: null,
            previousEditingGroup: null,
        }
    },
    computed: {
        sortedAvailableAttributeNames () {
            const attributeNames = Object.keys(this.value.availableAttributes)
            attributeNames.sort()
            return attributeNames
        },
        availableAttributesForGrouping () {
            if (!this.attributeGroups) {
                return []
            }
            const presetAttributeNames = Object.keys(this.value.presetAttributes)
            const groups = Object.keys(this.catalogProductAttributes).filter(attributeName => {
                if (presetAttributeNames.includes(attributeName)) {
                    return false
                }
                if (attributeName.includes('#')) {
                    return false
                }
                if (this.editingGroup.includes(attributeName)) {
                    return false
                }

                return !this.attributeGroups.some(element => element.includes(attributeName))
            })
            groups.sort()
            return groups
        },
        attributeGroups: {
            get () {
                return this.value.attributeGroups || []
            },
            set (newValue) {
                const value = JSON.parse(JSON.stringify(this.value))
                value.attributeGroups = newValue
                this.beautifyAndEmitValue(value)
            },
        },
        catalogProductAttributes () {
            const attributes = {}
            Object.entries(this.product.productAttributes.selectedAttributes).forEach(([attributeName, attributeOptions]) => {
                attributes[attributeName] = attributeOptions.map(option => {
                    return typeof option === 'string' ? option : option.option
                })
            })
            return attributes
        },
    },
    methods: {
        addAttributeToGroup (attributeName) {
            const group = JSON.parse(JSON.stringify(this.editingGroup))
            group.push(attributeName)
            group.sort()
            this.$set(this, 'editingGroup', group)
        },
        triggerAddNewGroup () {
            this.editingGroup = []
            this.previousEditingGroup = null
        },
        triggerEditGroup (attributeGroup) {
            this.editingGroup = JSON.parse(JSON.stringify(attributeGroup))
            this.previousEditingGroup = JSON.parse(JSON.stringify(attributeGroup))
        },
        deleteGroup () {
            const attributeNames = JSON.parse(JSON.stringify(this.previousEditingGroup))
            const groupName = attributeNames.join('#')

            const value = JSON.parse(JSON.stringify(this.value))
            value.attributeGroups = value.attributeGroups.filter(group => {
                return JSON.stringify(group) !== JSON.stringify(attributeNames)
            })
            delete value.availableAttributes[groupName]
            delete value.selectedAttributes[groupName]
            this.beautifyAndEmitValue(value)

            // if we dont have any presets left
            if (value.presetAttributes === null || Object.keys(value.presetAttributes).length === 0) {
                attributeNames.forEach(attributeName => {
                    value.availableAttributes[attributeName] = this.catalogProductAttributes[attributeName]
                })
                this.beautifyAndEmitValue(value)
                this.$emit('update:isEditingGroups', false)
            } else {
                const payload = {
                    productConfigurationTemplateId: this.product.productConfigurationId,
                    presetAttributes: value.presetAttributes,
                    selectedAttributes: value.selectedAttributes,
                }

                const request = this.post(CONFIG.API.ROUTES.CATALOG.GET_ATTRIBUTES_FOR_CATALOG_BY_PRESET, payload)
                    .then(({ data }) => {
                        attributeNames.forEach(attributeName => {
                            value.availableAttributes[attributeName] = data.availableAttributes[attributeName]
                        })
                        this.beautifyAndEmitValue(value)
                        this.$emit('update:isEditingGroups', false)
                        return { data }
                    })

                attributeNames.forEach(attributeName => {
                    value.availableAttributes[attributeName] = request.then(({ data }) => {
                        value.availableAttributes[attributeName] = data.availableAttributes[attributeName]
                        this.beautifyAndEmitValue(value)
                        return data.availableAttributes[attributeName]
                    })
                })
            }

            this.deleteGroupDialogIsOpen = false
            this.editingGroup = null
            this.previousEditingGroup = null
        },
        saveGroup () {
            const groupDetails = JSON.parse(JSON.stringify(this.editingGroup))
            const groupName = groupDetails.join('#')
            const value = JSON.parse(JSON.stringify(this.value))

            if (this.previousEditingGroup) {
                const previousGroupName = this.previousEditingGroup.join('#')
                value.attributeGroups = value.attributeGroups.filter(group => {
                    return JSON.stringify(group) !== JSON.stringify(this.previousEditingGroup)
                })
                value.availableAttributes = Object.fromEntries(Object.entries(value.availableAttributes).filter(([attributeName, attributeOptions]) => {
                    return attributeName !== previousGroupName
                }))
            }

            value.availableAttributes = Object.fromEntries(Object.entries(value.availableAttributes).filter(([attributeName, attributeOptions]) => {
                return !groupDetails.includes(attributeName)
            }))

            value.selectedAttributes = Object.fromEntries(Object.entries(value.selectedAttributes).filter(([attributeName, attributeOptions]) => {
                return !groupDetails.includes(attributeName)
            }))

            const attributeGroupsPayload = Object.fromEntries(groupDetails.map(attributeName => {
                const attributeOptions = this.catalogProductAttributes[attributeName]
                return [attributeName, attributeOptions]
            }))

            const payload = {
                productConfigurationTemplateId: this.product.productConfigurationId,
                presetAttributes: this.value.presetAttributes,
                attributeGroups: attributeGroupsPayload,
            }

            value.availableAttributes[groupName] = this.post(CONFIG.API.ROUTES.CATALOG.GET_ATTRIBUTES_FOR_CATALOG_BY_PRESET, payload)
                .then(({ data }) => {
                    const value = JSON.parse(JSON.stringify(this.value))
                    value.availableAttributes[groupName] = data.availableAttributes[groupName]
                    this.beautifyAndEmitValue(value)
                    return data.availableAttributes[groupName]
                })

            value.attributeGroups.push(groupDetails)
            this.beautifyAndEmitValue(value)
            this.$emit('update:isEditingGroups', false)
            this.editingGroup = null
        },
        setAttributeValues (attributeName, attributeOptions) {
            const value = JSON.parse(JSON.stringify(this.value))
            value.selectedAttributes[attributeName] = attributeOptions
            this.beautifyAndEmitValue(value)
        },
        beautifyAndEmitValue (value = null) {
            if (value === null) {
                value = JSON.parse(JSON.stringify(this.value))
            }
            value.selectedAttributes = Object.fromEntries(Object.entries(value.selectedAttributes)
                .map(([attributeName, values]) => {
                    return [attributeName, values.map(value => {
                        return typeof value === 'string' ? value : value.option
                    })]
                }))
            this.$emit('input', value)
        },
    },
}
</script>
<style scoped lang="scss">
.toggle-groups {
    margin-top: -55px;
    float: right;
}

.slide {
    transition: all 200ms;
    margin-top: 0;
}

.slide-selected-enter,
.slide-selected-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

.slide-selected-leave-active,
.slide-available-leave-active {
    position: absolute;
}

.slide-available-enter,
.slide-available-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}
.slide-available-enter {
    margin-top: -30px;
}
</style>
