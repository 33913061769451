<template>
    <div class="row">
        <div class="col-5 pt-2 border-right border-gray-200">
            <mercur-item
                v-for="(attributeName, key) in Object.keys(attributesList)"
                :data-e2e="'catalogProductContentSectionVariantAvailableAttributes'+key"
                :key="attributeName"
                @click.native="activeAttribute = attributeName"
            >
                <span :class="{'font-weight-bold': activeAttribute === attributeName }" class="pr-2">
                    {{ attributeName }}
                </span>
                <em
                    v-if="presetSelection[attributeName]"
                    :class="{'font-weight-bold': activeAttribute === attributeName }"
                >{{ presetSelection[attributeName] }}</em>
            </mercur-item>
        </div>

        <div class="col-7 position-relative">
            <h3 class="font-weight-normal" v-if="activeAttribute">Options for <em>{{ activeAttribute }}</em>
                <a
                    v-if="presetSelection[activeAttribute]"
                    class="pl-4"
                    @click.prevent="clearAttributeSelection(activeAttribute)"
                    href="#"
                ><small>Clear</small></a>
            </h3>
            <mercur-radio
                v-for="(attributeOption, key) in attributesList[activeAttribute]"
                :data-e2e="'catalogProductContentSectionVariantAvailableAttributeOption'+key"
                :key="key"
                :value="attributeOption.option"
                class="mr-4 mb-2"
                @change="emitValue"
                v-model="presetSelection[activeAttribute]"
            >
                {{ attributeOption.option }}
            </mercur-radio>
        </div>
    </div>
</template>
<script>
export default {
    name: 'VariantAttributePresetSelection',
    props: {
        attributesList: {
            type: Object,
            required: true,
        },
        value: {
            required: false,
            default: () => ({}),
        },
    },
    data () {
        return {
            presetSelection: {},
            activeAttribute: null,
        }
    },
    methods: {
        clearAttributeSelection (attributeName) {
            this.$delete(this.presetSelection, attributeName)
            this.$emit('clearAttribute')
            this.emitValue()
        },
        emitValue () {
            this.$emit('input', this.presetSelection)
        },
    },
    created () {
        if (Array.isArray(this.value) && this.value.length === 0) {
            this.$set(this, 'presetSelection', {})
            return
        }
        this.$set(this, 'presetSelection', this.value)
    },
}
</script>
<style scoped lang="scss">
.clear-preset-selection {
    position: absolute;
    right: 30px;
    top: -20px;
}
</style>
