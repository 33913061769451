<template>
    <div>
        <div v-if="isEditingPresets">
            <h3>Edit presets</h3>
            <a
                v-if="this.presetAttributes.length !== 0"
                @click="clearAllAttributeSelection()"
                href="#"
            ><small>Clear All</small></a>
            <alert type="danger" v-if="isInvalidPresetError">
                The attributes/options you have selected are not possible. Please fix this and save again.
            </alert>
            <variant-attribute-preset-selection
                v-if="!isFetchingPresetAttributesList"
                :attributes-list="presetAttributesList"
                v-model="presetAttributes"
                @clearAttribute="handleClearPreset"
                @input="reloadOptions"
            ></variant-attribute-preset-selection>
            <div v-if="isFetchingPresetAttributesList">
                <b>Loading attributes, please wait...</b>
            </div>
            <div class="d-flex">
                <mercur-button
                    v-if="!isFetchingPresetAttributesList"
                    data-e2e="catalogProductContentSectionVariantAttributesSaveButton"
                    class="btn btn-yellow ml-auto"
                    @click="savePresetsAndContinueToGroups()"
                >Save and continue to groups
                </mercur-button>
            </div>
        </div>
        <div v-else>
            <h3>
                Bundle
                <mercur-button class="btn btn-raised" @click="isViewPresetDialogOpen = true">View preset</mercur-button>
            </h3>

            <mercur-button v-if="!isEditingGroups" class="btn btn-raised toggle-groups" @click="isEditingGroups = true">
                Edit groups
            </mercur-button>
            <mercur-button v-else class="btn btn-raised toggle-groups" @click="isEditingGroups = false">
                cancel
            </mercur-button>

            <alert type="danger" v-if="isInvalidBundleError && !isEditingGroups">
                Every attribute must have at least one selected option. Please fix this and save again.
            </alert>

            <variant-attribute-bundle-selection
                :all-attributes="value.availableAttributes"
                :value="value"
                @input="handleBundleSelection"
                :product="product"
                :is-editing-groups.sync="isEditingGroups"
            ></variant-attribute-bundle-selection>

            <div class="d-flex">
                <mercur-button
                    class="btn btn-yellow ml-auto"
                    @click="saveBundleSelection()"
                    v-if="!isEditingGroups"
                    :disabled="!isDirty"
                >Save bundle selection
                </mercur-button>
            </div>
            <mercur-dialog :is-open.sync="isViewPresetDialogOpen">
                <h3 slot="header">Preset selection</h3>
                <div v-if="value.presetAttributes && Object.keys(value.presetAttributes).length !== 0">
                    <div v-for="(presetValue, presetAttribute) in value.presetAttributes" :key="presetAttribute">
                        <strong>{{presetAttribute}}</strong>: {{presetValue}}
                    </div>
                </div>
                <div v-else>
                    <em>No preset selected</em>
                </div>
                <div slot="footer" class="flex-grow-1 d-flex justify-content-between">
                    <mercur-button class="btn btn-danger" @click="isEditPresetWarningDialogOpen = true">Edit preset</mercur-button>
                    <mercur-button class="btn btn-yellow" @click="isViewPresetDialogOpen = false">Close</mercur-button>
                </div>
            </mercur-dialog>
        </div>
        <mercur-dialog :is-open.sync="isEditPresetWarningDialogOpen">
            <h3 slot="header">Warning</h3>
            <p>If you change the preset all the currently selected attribute options will be reset since the data might
                not be valid anymore.
                <br/>Are you sure you want to continue?</p>
            <div slot="footer">
                <mercur-button class="btn btn-yellow" @click="triggerEditPresets()">Yes, reset selected attributes
                </mercur-button>
                <mercur-button class="btn" @click="isEditPresetWarningDialogOpen = false">No, keep current preset</mercur-button>
            </div>
        </mercur-dialog>
    </div>
</template>
<script>
import Alert from '../Alert'
import CONFIG from '@root/config'
import VariantAttributePresetSelection from '@/components/elements/catalog/VariantAttributePresetSelection'
import VariantAttributeBundleSelection from '@/components/elements/catalog/VariantAttributeBundleSelection'

export default {
    name: 'VariantAttributeSelection',
    components: { VariantAttributeBundleSelection, VariantAttributePresetSelection, Alert },
    props: {
        product: {
            type: Object,
            required: true,
        },
        allAttributes: {
            type: Object,
            required: true,
        },
        value: {
            type: Object,
        },
    },
    data () {
        return {
            activeStep: 'changePresets',
            isInvalidPresetError: false,
            isInvalidBundleError: false,
            isViewPresetDialogOpen: false,
            isEditPresetWarningDialogOpen: false,
            isEditingGroups: false,
            presetAttributes: {},
            bundleSelection: {},
            historyValue: null,
            isFetchingPresetAttributesList: false,
            attributesForPresetList: {},
            reloadCleanedPreset: false,
        }
    },
    computed: {
        isEditingPresets () {
            if (!this.value) {
                return true
            }
            if (this.value.availableAttributes === null) {
                return true
            }
            if (!this.value.presetAttributes) {
                return true
            }
            return false
        },
        isDirty () {
            return JSON.stringify(this.value) !== JSON.stringify(this.historyValue)
        },
        presetAttributesList () {
            return Object.keys(this.attributesForPresetList).length > 0
                ? this.attributesForPresetList
                : JSON.parse(JSON.stringify(this.allAttributes))
        },
    },

    methods: {
        async triggerEditPresets () {
            this.isEditPresetWarningDialogOpen = false
            this.isViewPresetDialogOpen = false
            this.attributesForPresetList = {}
            this.updateValueSections([
                {
                    sectionName: 'availableAttributes',
                    sectionValue: null,
                },
                {
                    sectionName: 'selectedAttributes',
                    sectionValue: {},
                },
            ])

            if (Object.keys(this.value.presetAttributes).length > 0) {
                await this.buildPresetListFromProductPreset(this.value.presetAttributes)
            }
        },
        clearAllAttributeSelection () {
            this.presetAttributes = {}
            this.savePresetsAndContinueToGroups()
        },
        savePresetsAndContinueToGroups () {
            if (!this.presetAttributes || Object.keys(this.presetAttributes).length === 0) {
                const availableAttributes = {}
                Object.entries(this.product.productAttributes.selectedAttributes).forEach(([attributeName, attributeOptions]) => {
                    availableAttributes[attributeName] = attributeOptions.map(option => {
                        return typeof option === 'string' ? option : option.option
                    })
                })
                this.updateValueSections([{
                    sectionName: 'presetAttributes',
                    sectionValue: {},
                },
                {
                    sectionName: 'availableAttributes',
                    sectionValue: availableAttributes,
                },
                {
                    sectionName: 'attributeGroups',
                    sectionValue: [],
                },
                ])
                this.$emit('triggerSave')
                return
            }
            const payload = {
                productConfigurationTemplateId: this.product.productConfigurationId,
                presetAttributes: this.presetAttributes,
            }
            this.isInvalidPresetError = false

            this.post(CONFIG.API.ROUTES.CATALOG.GET_ATTRIBUTES_FOR_CATALOG_BY_PRESET, payload)
                .then(({ data }) => {
                    if (data.isPresetSelectionValid === false) {
                        this.isInvalidPresetError = true

                        return
                    }
                    this.updateValueSections([{
                        sectionName: 'presetAttributes',
                        sectionValue: data.presetAttributes,
                    },
                    {
                        sectionName: 'availableAttributes',
                        sectionValue: data.availableAttributes,
                    },
                    {
                        sectionName: 'attributeGroups',
                        sectionValue: data.attributeGroups,
                    },
                    ])
                    this.$emit('triggerSave')
                })
        },
        handleBundleSelection (value) {
            this.updateValueSections([{
                sectionName: 'selectedAttributes',
                sectionValue: value.selectedAttributes,
            },
            {
                sectionName: 'availableAttributes',
                sectionValue: value.availableAttributes,
            }, {
                sectionName: 'attributeGroups',
                sectionValue: value.attributeGroups,
            },
            ])
        },
        saveBundleSelection () {
            // check if every available attribute has at least one value selected
            this.isInvalidBundleError = Object.keys(this.value.availableAttributes).length !==
                Object.keys(this.value.selectedAttributes).filter((attribute) => this.value.selectedAttributes[attribute].length).length

            if (!this.isInvalidBundleError) {
                this.$emit('triggerSave')
                this.$set(this, 'historyValue', JSON.parse(JSON.stringify(this.value)))
            }
        },

        updateValueSections (sections) {
            const value = JSON.parse(JSON.stringify(this.value))
            sections.forEach(section => {
                value[section.sectionName] = section.sectionValue
            })

            this.$emit('input', value)
        },
        handleClearPreset () {
            this.reloadCleanedPreset = true
        },
        async reloadOptions (presetSelection) {
            await this.buildPresetListFromProductPreset(presetSelection)
        },
        async buildPresetListFromProductPreset (presetAttributes) {
            this.attributesForPresetList = {}

            if (Object.keys(presetAttributes).length === 0) {
                return
            }

            this.isFetchingPresetAttributesList = true

            const payload = {
                productConfigurationTemplateId: this.product.productConfigurationId,
                presetAttributes: presetAttributes,
            }

            return this.post(CONFIG.API.ROUTES.CATALOG.GET_ATTRIBUTES_FOR_CATALOG_BY_PRESET, payload)
                .then(({ data }) => {
                    let attributes = {}
                    if ('presetAttributes' in data) {
                        for (let attrName in data.presetAttributes) {
                            attributes[attrName] = [{ option: data.presetAttributes[attrName] }]
                        }
                    }

                    if ('availableAttributes' in data) {
                        for (let attrName in data.availableAttributes) {
                            let options = []

                            for (let attrOption in data.availableAttributes[attrName]) {
                                options.push({ option: data.availableAttributes[attrName][attrOption] })
                            }

                            attributes[attrName] = options
                        }
                    }

                    this.attributesForPresetList = Object.keys(attributes).sort().reduce(
                        function (result, key) {
                            result[key] = attributes[key]
                            return result
                        },
                        {}
                    )
                    if (!this.reloadCleanedPreset) {
                        this.$set(this, 'presetAttributes', data.presetAttributes)
                    }

                    this.reloadCleanedPreset = false
                    this.isFetchingPresetAttributesList = false
                })
        },
    },
    created () {
        this.$set(this, 'presetAttributes', this.value.presetAttributes)
        this.$set(this, 'bundleSelection', this.value.selectedAttributes)
        this.$set(this, 'historyValue', JSON.parse(JSON.stringify(this.value)))
    },
}
</script>
<style lang="scss" scoped>
.link {
    cursor: pointer;
}

.attribute-groups {
    &__group {
        position: relative;
        border-left: 5px solid #cacaca;
        padding-left: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
        border-radius: 3px;
        margin-bottom: 15px;
        margin-top: 5px;
    }

    &__dropzone {
        position: relative;
        margin-top: 10px;
        border: 2px dashed #acacac;
        background-color: lighten(#acacac, 40%);
        border-radius: 3px;
        padding: 4px;
        text-align: center;
    }

    &__ghost-item {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .attribute-groups__attribute-icon {
            display: none;
        }

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 11px;
            content: 'Add option to this group';
            background-color: white;
        }
    }

    &__attribute {
        cursor: grab;
    }

    &__attribute-icon {
        vertical-align: middle;
        margin-bottom: 6px;
        margin-top: 4px;
    }
}

.toggle-groups {
    margin-top: -55px;
    float: right;
}
</style>
