<template>
    <div>
        <div class="pb-10" v-for="(vatApplication, vatApplicationId) in productVariant.assignedVatProfiles" :key="vatApplicationId">
            <strong>{{getApplicationNameForApplicationId(vatApplicationId)}}</strong><br /><small>{{vatApplicationId}}</small>:<br />
            <table class="vat-profile">
                <tr>
                    <td></td>
                    <td><small>Products</small></td>
                    <td><small>Shipping</small></td>
                </tr>
                <tr v-for="(vatProfile, vatApplicationCountryCode) in vatApplication" :key="vatApplicationCountryCode">
                    <td><small>{{vatApplicationCountryCode}}</small>:</td>
                    <td class="text-right">{{vatProfile.vatProducts * 100}}%</td>
                    <td class="text-right">{{(vatProfile.vatShipping * 100)}}%</td>
                    <td class="text-right"><small>{{(vatProfile.vatProfileLabel)}}</small></td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'CatalogProductVatProfilesList',
    props: {
        productVariant: {
            required: true,
        },
    },
    computed: {
        ...mapState('shop', ['shops']),
    },
    methods: {
        getApplicationNameForApplicationId (applicationId) {
            const application = this.shops.find(application => application.applicationId === applicationId)
            if (application) {
                return application.applicationName
            }
            return applicationId
        },
    },
}
</script>
<style scoped lang="scss">
.vat-profile {
    width: 100%;
    border-collapse: collapse;
    tr {
        &:nth-child(even) {
            background-color: #efefef;
        }
    }
}
</style>
