<template>
    <div class="mt-4">
        <div class="col-2 pt-4 float-left">
            <mercur-checkbox v-model="value.active">Active</mercur-checkbox>
        </div>
        <div class="col-6 float-left">
            <mercur-input required v-model="value.productName">
                Product Name
            </mercur-input>
        </div>
    </div>
</template>
<script>

export default {
    name: 'CatalogProductSettingEcoCart',
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
    data () {
        return {
            configuration: {
                active: false,
                productName: null,
            },
        }
    },
}
</script>
<style scoped lang="scss">
.float-left {
    float: left;
    margin-left: -13px;
}
</style>
