<template>
    <mercur-card class="card product-content-section">
        <h4 v-if="title">{{title}}</h4>
        <slot name="topActions"></slot>
        <div>
            <slot v-if="section" :section="vForm[section]"></slot>
            <slot v-else></slot>
        </div>
        <div v-if="isLoaded && !isMainProduct && section">
            <mercur-button class="btn" @click="discardChanged()" :disabled="!vForm[section].$anyDirty || isSaving">Discard changes</mercur-button>
            <mercur-button
                :data-e2e="'catalogProductContentSectionVariantInformationSaveButton-'+section"
                @click="save(section)" class="btn btn-primary"
                :disabled="isSaving || !vForm[section].$anyDirty || vForm[section].$invalid">
                Save changes
            </mercur-button>
        </div>
    </mercur-card>
</template>
<script>
export default {
    name: 'CatalogProductContentSection',
    props: {
        title: {
            default: null,
            type: String,
        },
        section: {
            default: '',
            type: String,
        },
        vForm: {
            type: Object,
            default: null,
        },
        getProductContentBySection: {
            type: Function,
        },
        catalogProductId: {
            type: String,
        },
        productContentId: {
            type: String,
        },
        isLoaded: {
            type: Boolean,
        },
        isMainProduct: {
            type: Boolean,
        },
    },
    computed: {
        isSaving () {
            return this.$store.state.catalog.catalogProductsSaving[this.catalogProductId]
        },
    },
    methods: {
        discardChanged () {
            this.$set(this.vForm[this.section], '$model', this.getProductContentBySection(this.section))
            this.$nextTick(() => {
                this.vForm[this.section].$reset()
            })
        },
        save () {
            const payload = {
                catalogProductId: this.catalogProductId,
                section: this.section,
                payload: JSON.parse(JSON.stringify(this.vForm[this.section].$model)),
                productContentId: this.productContentId,
            }
            this.$store.dispatch('catalog/saveCatalogProductContentSection', payload).then(() => {
                this.$emit('sectionSaved', payload)
                this.$root.$emit('notification:global', {
                    message: 'Successfully saved',
                })
                this.$nextTick(() => {
                    this.vForm[this.section].$reset()
                })
            })
        },
    },
}
</script>
<style scoped lang="scss">
    .action-button[disabled] {
        opacity: 0.7;
    }
</style>
