<template>
    <div class="mt-4">
        <div class="col-12 pt-4 float-left">
            <mercur-checkbox v-model="value.active">Active</mercur-checkbox>
        </div>
    </div>
</template>
<script>

export default {
    name: 'CatalogProductSettingFastEditor',
    props: {
        value: {
            type: Object,
            default: () => {
            },
        },
    },
    data () {
        return {
            configuration: {
                active: false,
            },
        }
    },
}
</script>
<style scoped lang="scss">
.float-left {
    float: left;
    margin-left: -13px;
}
.d-inline-block+.d-inline-block {
    margin-left: 15px;
}
</style>
