import CONFIG from '@root/config'

export default {
    data () {
        return {
            uploads: {},
            imageUrls: {},
        }
    },
    computed: {
        images () {
            if (!this.form.images.productContentImages) {
                return null
            }
            return Object.fromEntries(Object.entries(this.$v.form.images.productContentImages.$each.$iter).slice(0, 3).sort(([, a], [, b]) => a.$model.imagePosition - b.$model.imagePosition))
        },
        imagesSize () {
            return Object.entries(this.form.images.productContentImages).length
        },
    },
    methods: {
        getImageUrl ({ percentCompleted, objectName }) {
            if (typeof percentCompleted !== 'undefined' && percentCompleted !== 100) {
                return ''
            }
            if (this.imageUrls[objectName] || this.imageUrls[objectName] === null) {
                return `url(${this.imageUrls[objectName]})`
            }

            const url = CONFIG.API.ROUTES.CATALOG.IMAGES.GET_PREVIEW_URL.replace('{catalogProductId}', this.catalogProductId)

            this.$set(this.imageUrls, objectName, null)
            this.$api.post(url, {
                files: [objectName],
            }).then(({ data }) => {
                this.$set(this.imageUrls, objectName, data.find(result => result.objectName === objectName).url)
            })
        },
    },
}
