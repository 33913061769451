<template>
    <div class="mt-4">
        <div class="col-6 float-left">
            <pretty-select
                placeholder="Claim"
                :options="availableClaims"
                :get-option-label="claim => claim.label"
                :get-option-key="claim => claim.value"
                :reduce="claim => claim.value"
                :multiple="true"
                v-model="value.claim"
            ></pretty-select>
        </div>
    </div>
</template>
<script>

import PrettySelect from '@/components/utils/PrettySelect'

export default {
    name: 'CatalogProductSettingClaims',
    components: { PrettySelect },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
    data () {
        return {
            availableClaims: [
                { value: 'LIM', label: 'Low Impact Materials' },
                { value: 'SU', label: 'Sustainable Use' },
                { value: 'PP', label: 'People and Planet' },
                { value: 'RHS', label: 'Reduced Harmful Substances' },
            ],
            configuration: {
                claim: null,
            },
        }
    },
}
</script>
<style scoped lang="scss">
.float-left {
    float: left;
    margin-left: -13px;
}
</style>
