<template>
    <div>
        <span style="float: right">{{ params.value }}</span>
        <div class="c-color" :style="bgColor"></div>
    </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'ColorCell',
    data () {
        return {
        }
    },
    computed: {
        bgColor () {
            return {
                '--bg-color': this.params.value,
            }
        },
    },
})
</script>

<style lang="scss" scoped>
    .c-color {
        background-color: var(--bg-color);
        display: inline-block;
        padding: 10px 10px;
        margin: 7px;
        float: right;
    }
</style>
