<template>
    <div class="mt-4">
        <div class="col-4 float-left">
            <pretty-select
                placeholder="Template Type"
                :options="availableEditors"
                v-model="value.editor"
            ></pretty-select>
        </div>
        <div class="col-4 float-left">
            <mercur-input required v-model="value.templateName">
                Template Name
            </mercur-input>
        </div>
        <div class="col-3 float-left">
            <mercur-input required v-model="value.colorCode">
                Hex Color Code
            </mercur-input>
        </div>
        <div class="col-1 float-left">
            <v-input-colorpicker v-model="value.colorCode"/>
        </div>
    </div>
</template>
<script>
import PrettySelect from '../../../components/utils/PrettySelect'
import InputColorPicker from 'vue-native-color-picker'

export default {
    name: 'CatalogProductSettingEditor',
    components: { PrettySelect, 'v-input-colorpicker': InputColorPicker },
    props: {
        value: {
            type: Object,
            default: () => {
            },
        },
    },
    data () {
        return {
            availableEditors: ['pitchprint'],
            configuration: {
                editor: null,
                templateName: null,
                colorCode: null,
            },
        }
    },
}
</script>
<style scoped lang="scss">
.float-left {
    float: left;
    margin-left: -13px;
}

label {
    font-size: 14px;
    font-weight: bold;
}

input[type="color"] {
    margin-top: 27px;
    margin-left: -15px;
}
</style>
