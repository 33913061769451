<template>
    <div class="catalog-product-applications">
        <div class="catalog-product-applications__list">
            <div v-if="selectedShops.length === 0">
                <em>No applications assigned</em>
            </div>
            <div
                v-for="(application, key) in selectedShops"
                class="d-flex align-items-center"
                :key="key"
            >
                <div class="flex-1 mr-2">
                    <span>{{application.applicationName}}</span>
                    <status-chip>{{application.status}}</status-chip>
                </div>

                <div>
                    <mercur-menu>
                        <mercur-button class="btn btn-icon"><i class="fas fa-ellipsis-v"></i></mercur-button>
                        <div slot="dropdown">
                            <mercur-item @click.native="deleteApplication(application)" :disabled="selectedShops.length <= 1"><i class="fas fa-trash"></i><span>Delete</span></mercur-item>
                        </div>
                    </mercur-menu>
                </div>
            </div>
        </div>
        <mercur-button class="btn w-100 mt-2" @click="isApplicationSelectDialogOpen = true">Modify applications</mercur-button>
        <mercur-dialog
            :is-open.sync="isApplicationSelectDialogOpen"
            :width="'900px'"
        >
            <form @submit.prevent="saveSelectedApplications(applicationDialogPendingApplications)">
                <div class="catalog-product-applications__dialog">
                    <application-selector
                        v-if="isApplicationSelectDialogOpen"
                        class="h-100 d-flex flex-column"
                        :active-step.sync="applicationSelectionWizard"
                        :value="value"
                        @input="applicationDialogPendingApplications = $event"
                        :product="product"
                    ></application-selector>
                </div>
                <mercur-spinner v-if="isPending"></mercur-spinner>
                <div slot="footer" v-if="applicationDialogPendingApplications.length">
                    <mercur-button class="btn" :disabled="isPending" @click.native="isApplicationSelectDialogOpen = false">Close</mercur-button>
                    <mercur-button class="btn btn-primary btn-raised" type="submit" :disabled="isPending || applicationDialogPendingApplications.length === 0">Add products to catalog</mercur-button>
                </div>
            </form>
        </mercur-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import CONFIG from '@root/config'
import StatusChip from '../../utils/StatusChip'
import ApplicationSelector from '../../../components/elements/catalog/ApplicationSelector'

export default {
    name: 'CatalogProductAssignedApplications',
    components: { ApplicationSelector, StatusChip },
    props: {
        value: { },
        catalogProductId: {
            type: String,
            required: true,
        },
        product: { },
    },
    data () {
        return {
            applicationSelectionWizard: 0,
            isApplicationSelectDialogOpen: false,
            applicationDialogPendingApplications: [],
            isPending: false,
        }
    },
    computed: {
        ...mapState('shop', ['shops']),
        selectedShops () {
            let val = this.value
            if (this.value && !Array.isArray(this.value)) {
                val = Object.values(this.value)
            }
            return val.map(selectedApplication => {
                const application = this.shops.find(availableShop => availableShop.applicationId === selectedApplication.applicationId)

                return {
                    applicationName: application ? application.applicationName : application.applicationId,
                    ...selectedApplication,
                }
            })
        },
    },
    methods: {
        deleteApplication (applicationToRemove) {
            const applicationSet = this.selectedShops.filter(selectedShop => selectedShop.applicationId !== applicationToRemove.applicationId)
            this.saveSelectedApplications(applicationSet)
        },

        saveSelectedApplications (applications) {
            this.isPending = true
            const payload = {
                catalogProductId: this.catalogProductId,
                applications,
            }

            this.post(
                CONFIG.API.ROUTES.CATALOG.SET_CATALOG_PRODUCT_APPLICATIONS,
                payload,
                'Applications were successfully set',
                'Something went wrong. Please try again.'
            ).then(({ data }) => {
                this.$set(this, 'isApplicationSelectDialogOpen', false)
                setTimeout(() => {
                    this.$emit('forceFetchCatalogProduct')
                }, 100)
            }).finally(() => {
                this.isPending = false
            })
        },
    },
    created () {
        if (this.shops.length) {
            return
        }

        this.fetchShops().then(() => {
            this.checkConfig()
        })
    },
}

</script>

<style lang="scss" scoped>
.catalog-product-applications {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__list {
        overflow-y: auto;
        overflow-x: visible;
        max-height: 210px;
    }
    &__cell {
        font-size: 12px;
        line-height: 1.1em;
        word-break: break-all;
    }
    &__dialog {
        height: 90vh;
        max-height: 500px;
        backface-visibility: hidden;
    }
    &__dialog-actions {
        justify-content: space-between;
    }
}
</style>
