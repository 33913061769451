<template>
    <div>
        <div v-if="Array.isArray(localAttributeOptions)">
            <mercur-chip
                v-for="attributeOption in localAttributeOptions"
                :key="attributeOption"
                :clickable="true"
                :class="{'attribute-section__option--is-selected': isAttributeOptionSelectedForEditor(attributeOption)}"
                class="attribute-section__option"
                @click="toggleOptionForEditor(attributeOption)"
            >
                {{attributeOption}}
            </mercur-chip>
        </div>
        <div v-else-if="isLoading">
            <mercur-spinner></mercur-spinner>
        </div>
        <div v-else>
            <em class="pl-4 error">No options found</em>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CatalogProductSettingAttributeOptionSelection',
    props: {
        attributeOptions: {
            type: [Array, Promise],
        },
        value: {
            type: Array,
        },
        attributeName: {
            type: String,
        },
    },
    data () {
        return {
            localAttributeOptions: null,
            isLoading: false,
        }
    },
    computed: {
        flatAttributeOptions () {
            return this.localAttributeOptions.map(attributeOption => {
                return typeof attributeOption === 'string' ? attributeOption : attributeOption.option
            })
        },
    },
    methods: {
        isAttributeOptionSelectedForEditor (attributeOption) {
            if (!this.value) {
                return false
            }

            return this.value.find(availableOption => availableOption === attributeOption)
        },
        toggleOptionForEditor (attributeOption) {
            const relatedAttributeOption = this.flatAttributeOptions.find(availableOption => availableOption === attributeOption)

            if (this.isAttributeOptionSelectedForEditor(attributeOption)) {
                this.$emit('input', this.value.filter(currentAttributeOption => currentAttributeOption !== attributeOption))
                return
            }

            this.$emit('input', [...[], relatedAttributeOption])
        },
    },
    created () {
        if (Array.isArray(this.attributeOptions)) {
            this.$set(this, 'localAttributeOptions', this.attributeOptions)
        } else {
            this.isLoading = true
            this.attributeOptions.then(attributeOptions => {
                if (!attributeOptions) {
                    this.$root.$emit('notification:global', {
                        message: `No valid options found for group: ${this.attributeName}`,
                        type: 'error',
                    })
                    return
                }
                this.$set(this, 'localAttributeOptions', attributeOptions)
            }).finally(() => {
                this.isLoading = false
            })
        }
    },
}
</script>
<style lang="scss" scoped>
@import "~@platform-frontend-components/mercur-components/src/assets/scss/_variables.scss";
    .attribute-section__option {
        background-color: $gray-600 !important;
        &--is-selected {
            background-color: $black !important;
        }
    }
</style>
